module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"NCLEX Practice Questions","titleTemplate":"NursingExamPro.com | %s","description":"Over 10,000 NCLEX practice questions with answers and rationales.","language":"en-us","canonical":"https://nursingexampro.com/","openGraph":{"title":"NCLEX Practice Questions","description":"Over 10,000 NCLEX practice questions with answers and rationales.","type":"website","locale":"en_US","url":"https://nursingexampro.com/","site_name":"NursingExamPro.com","images":[{"url":"https://nursingexampro.com/best_og_1200_630.png","width":1200,"height":630,"alt":"NursingExamPro.com"},{"url":"https://nursingexampro.com/nep_ig_og.jpg","width":1080,"height":1080,"alt":"NursingExamPro.com"}]},"twitter":{"handle":"@nursingexampro","site":"@nursingexampro","cardType":"summary_large_image"},"facebook":{"appId":"1410071936024100"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NursingExamPro.com","icon":"static/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Mukta:400,700,800"]},"custom":{"families":["Feather"],"urls":["/resources/fonts/font-feathericons/dist/feather.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-101593366-3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
